
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.request-from {
  $root: &;

  &__checkbox {
    margin-bottom: 26px;
  }

  &__select {
    position: relative;
  }

  &__selected-user {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 16px;
    width: 56px;
    height: 56px;
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-grayscale-40;
  }

  &__item {
    @include hover(true) {
      background-clip: red;
      #{$root}__user-name {
        color: $color-client-portal-logo;
      }
    }
  }

  &__user-contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__user-name {
    margin-right: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    transition: color $base-animation-time;
  }

  &__user-email {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-50;
    margin-right: 16px;
  }

  &__user-appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-40;
  }

  &__user-fired {
    font-size: 12px;
    line-height: 16px;
    color: $color-red;
  }
}
